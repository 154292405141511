import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "開発本部のセキュリティ知識を底上げする、タスクフォースの進め方",
  "date": "2017-10-20T03:20:00.000Z",
  "slug": "entry/2017/10/20/122000",
  "tags": ["medley"],
  "hero": "./2017_10_20.png",
  "heroAlt": "security"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`ジョブメドレーの開発運用を担当している`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/54029"
      }}>{`新居`}</a>{`です。`}</p>
    <p>{`メドレーでは開発本部のメンバーの技術力底上げや課題解決を目的とした短期プロジェクト（タスクフォースと呼んでいます）を実施しています。この取り組みの一環として、6〜8 月はセキュリティ知識の底上げを目指した「セキュリティタスクフォース」を実施しました。今回は、その取り組み内容を紹介します。`}</p>
    <h1>{`背景`}</h1>
    <p>{`現在、メドレーの開発本部には約 20 名のエンジニアが在籍しており、それぞれ多種多様な開発経験やスキルセットを持ったエンジニアが集まっています。`}</p>
    <p>{`そして、前職ではフロントエンド専門でやってきたエンジニアもサーバーサイドの開発を行ったり、またその逆のケースもあったりと、各自の専門領域にとらわれないスタイルでの開発を行うことも多々あります。`}</p>
    <h1>{`課題`}</h1>
    <p>{`そういった背景の中、エンジニアが増えていくにつれエンジニア間のスキルセットに差が生まれ、ばらつきが見られるようにもなっています。今後、更に組織が拡大していくのに伴い、こうしたエンジニア間のスキルセットの差も大きくなっていくことが懸念されます。`}</p>
    <p>{`また、メドレーは医療ヘルスケア分野に向けてサービス提供を行っており、多くの個人情報を扱っていることはもちろん、医療・介護という領域の性質上、セキュリティには非常に気をつかう必要があります。データベースに入れて保守・運用している個人情報などの取り扱いや、システム改善や新機能開発などを行うときには必然的にセキュリティにも配慮して開発を進める必要があります。`}</p>
    <p>{`そして背景のところでも述べたように、元々フロントエンド専門のエンジニアがサーバーサイドの開発に関わるケースや、そもそもサーバーサイド開発の経験が浅いエンジニアも中にはいて、サーバーサイドのセキュリティに関して自信がなかったり、具体的な対策方法がすぐに出てこないこともあるという課題がありました（当然ですが実際の開発では PR などでレビューして問題が起きないように対応しています）。`}</p>
    <p>{`そこで、そういったメンバーのセキュリティ知識の底上げを行い、エンジニア間のスキルセットの差を縮めていくことが必要であると考えました。`}</p>
    <h1>{`取り組み`}</h1>
    <p>{`上述した通り、`}</p>
    <ul>
      <li parentName="ul">{`スキルセットにばらつきや差が見られる`}</li>
      <li parentName="ul">{`医療ヘルスケア分野は特にセキュリティに気を使う必要がある`}</li>
      <li parentName="ul">{`セキュリティに関して自信がない、具体的な対策方法がすぐに出てこないこともある`}</li>
    </ul>
    <p>{`といった課題感から、まずはフロントエンド専門でやってきたメンバーやサーバーサイド開発の経験が浅いメンバーをメインターゲットとして、セキュリティ知識の底上げをやっていくことにフォーカスしました。`}</p>
    <p>{`目標としては、ウェブアプリケーション開発における最低限のセキュリティ知識や対策方法をしっかり再整理し、さらに開発で使っている Ruby on Rails 上でどのように対策するべきかを押さえるというところを目標におきました。`}</p>
    <h4>{`形式`}</h4>
    <p>{`形式としては、参加者に事前に教材の対象範囲を読んできてもらい、隔週開催の TechLunch（社内勉強会）終了後の約 20 分を利用して、内容の簡単な説明や補足、質疑応答、議論などを行う場（フォロー会）を設けました。`}</p>
    <h4>{`教材`}</h4>
    <p>{`以下を使用しました。`}</p>
    <p>{`メイン教材：「IPA の安全なウェブサイトの作り方」`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="安全なウェブサイトの作り方：IPA 独立行政法人 情報処理推進機構" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.ipa.go.jp%2Fsecurity%2Fvuln%2Fwebsecurity.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.ipa.go.jp/security/vuln/websecurity.html">www.ipa.go.jp</a></cite>
    <p>{`サブ教材：「Rails セキュリティガイド」`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="Ruby on Rails ガイド：体系的に Rails を学ぼう" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Frailsguides.jp%2Fsecurity.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://railsguides.jp/security.html">railsguides.jp</a></cite>
    <p>{`メイン教材の「IPA の安全なウェブサイトの作り方」は、IPA が届出を受けた脆弱性関連情報を基にして作られており、セキュリティを考慮したウェブサイトを作る上での最低限の知識が整理できるだろうということで採用しました。`}</p>
    <p>{`サブ教材の「Rails セキュリティガイド」は、Rails ではどのようにセキュリティの問題を回避しているのかといった方法が解説されており、実際の開発のときにどうすれば良いのかといったことが押さえられるだろうということで採用しました。`}</p>
    <p>{`実際の開催スケジュールと、フォロー会の対象範囲はこちら。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/d/dev-medley/20170925/20170925122130.png",
        "alt": "f:id:dev-medley:20170925122130p:plain",
        "title": "f:id:dev-medley:20170925122130p:plain"
      }}></img></p>
    <h1>{`取り組みを終えて`}</h1>
    <p>{`取り組みを終えて感じたこととしては以下になります。`}</p>
    <h4>{`技術的な観点`}</h4>
    <ul>
      <li parentName="ul">{`SQL インジェクションや XSS、CSRF などのメジャーな攻撃手法を参加者間で再整理できた`}</li>
      <li parentName="ul">{`技術的に不安なところなどは「ここはこうですよね？」という感じで確かめ合うことができた`}</li>
      <li parentName="ul">{`参加者が前職での経験談などをシェアしてくれる場面もあり、知見の共有ができた`}</li>
      <li parentName="ul">{`セキュリティに詳しいエンジニアも交えて話すことで、随所で効果的にツッコミをいただき、濃密な議論ができた`}</li>
      <li parentName="ul">{`ベテランエンジニアからは、昔流行った某サービスの某セキュリティ系障害の有名事例なども共有され、過去の歴史を知ることができる場となった`}</li>
    </ul>
    <h4>{`技術以外の観点`}</h4>
    <ul>
      <li parentName="ul">{`普段はチーム毎に黙々と仕事に取り組んでおり、チームを跨いであるひとつの話題（今回はセキュリティ）についてみんなと会話する機会は少ないので、知識の底上げはもちろんのこと、コミュニケーションの場としても良かった`}</li>
    </ul>
    <p>{`今回のように質疑応答や議論ができる場を設けることにより、他のメンバーの経験や知見も効果的に共有することができ、教材の読み合わせや講義形式では得られない知識も共有でき、取り組みとしてはうまくいったかなあと思います。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`ということで、今回はセキュリティタスクフォースについてご紹介しました。`}</p>
    <p>{`セキュリティの知識はだれかひとりが押さえていれば良いというものではなく、開発に関わるエンジニア全員が最低限は押さえておく必要があると思います。`}</p>
    <p>{`組織の拡大と共に、日々のプロダクトの運用・開発も大切ですが、それらを支えるエンジニアの知識の底上げなどの開発以外の部分もより大切になってきますし、そういった取り組みが組織力を高めていくのではないかと思います。`}</p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」、医師たちがつくるオンライン医療事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」、口コミで探せる`}<a parentName="p" {...{
        "href": "https://d.hatena.ne.jp/keyword/%B2%F0%B8%EE%BB%DC%C0%DF"
      }}>{`介護施設`}</a>{`の検索サイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」、オンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」などのプロダクトを提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>
    <p>{`医療や介護とは全く違う業界で経験を積んできたエンジニア・デザイナーが多いですが、こうした定期的な勉強会などで必要知識をインプットしながら開発しています。`}</p>
    <p>{`メドレーで一緒に医療体験を変えるプロダクト作りに関わりたい方のご連絡お待ちしております！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      